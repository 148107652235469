.dropdown {
    position: relative;
    width: 200px;
    cursor: pointer;
  }
  
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    /* border: 1px solid #ccc;
    border-radius: 4px; */
    background-color: #fff;
  }
  
  .icon {
    transition: transform 0.3s ease;
  }
  
  .icon.open {
    transform: rotate(180deg);
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .dropdown-menu li {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background-color: #f0f0f0;
  }
  